/* FileManager.css */

.file-manager {
  display: flex;
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: baseline;
  border: 3px solid #f0f0f0;
  border-radius: 5px;
  border-style: groove;
  padding: 20px;
  margin: 0px 0px 20px 0px;
  min-height:600px;
  width: 100%;
}

.file-manager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid #ccc;
  border-style: groove;
  background-color: #f3f3f3;
  padding: 10px 40px;
}

.login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.file-uploader {
  display: flex;
  width: 100%;
  /*flex-direction: column;*/
  align-items: center;
  justify-content: space-around;
  /*margin-top: 20px;*/
  border:2px solid rgb(247, 245, 245);
  border-style: groove;
  background-color: #fafafa;
}

.file-uploader-header {
  max-width:100%;
  font-size:large;
}

.margin-parent {
  margin: 0px 40px;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload .upload-button {
  display: flex;
  border: 2px solid darkgray;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: lightgray;
  cursor: pointer;
}

.file-upload .upload-button:hover {
  background-color: gray;
}

.file-upload progress {
  width: 100%;
  margin-bottom: 10px;
}

.file-browser {
  max-width: 100%;;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.file-browser .file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-browser .file-table th,
.file-browser .file-table td {
  padding: 5px 10px;
  text-align: left;
  
}

.font-all {
  font-family: Nunito Sans, sans-serif;
}

.file-browser .file-table th {
  background-color: #e8e8e8;
  inline-size: 150px;
}

.file-browser .file-table .table-row:nth-child(even) {
  background-color: #f9f9f9; 
}

.copy-success {
  background-color: green;
  color: white;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.user-info {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.user-actions {
  display: flex;
  align-items: center;
}

.user-email {
  margin-right: 0px;
}

.user-profile-widget {
  display: flex; 
  flex-direction: row;
  align-items: initial;
  font-family: Nunito Sans, sans-serif;
  border: 4px solid #f0f0f0;
  border-radius: 5px;
  border-style: groove;
  padding: 20px;
  margin: 0px;
  width: 100%;
  background-color: #f3f3f3;
  
}
.user-profile-widget-box-parent {
  display: inline-grid;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  border: 4px solid #ccc;
  border-style: groove;
  margin: 0px 0px 0px 20px;

  padding: 10px;
 
  
}

.user-profile-widget-box {
  display: flex;
  align-items: center;
  
  /*background-color: #f0f0f0;
  border-style: groove;
  
  max-width: 1440px;
  padding: 10px;*/
}

.user-profile-info {
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 10px;
  margin: 0px 0px 0px 20px;
  border-style: groove;
}

.user-profile-info .user-email {
  font-size: medium;
  margin: 0px;
}

.user-profile-info .display-name {
  font-size: medium;
  margin: 0px 0px 5px 0px;
}

.sign-out-button {
  background-color: darkblue;
  color: #f7c639;
  border: none;
  padding: 12px 25px;
  margin: 10px 20px 10px 20px;
  font-weight: bold;
  font-family: Nunito Sans, sans-serif;
  transition: background-color 0.3s;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #f7c639;
  color: darkblue;
}

.sign-in-button:hover {
  background-color: #f7c639;
  color: darkblue;
}

.file {
    background-color: darkblue;
    color: #f7c639;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    font-family: sans-serif;
    transition: background-color 0.3s;
    cursor: pointer;
  }

.sign-in-button {
  background-color: darkblue;
  color: #f7c639;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-family: sans-serif;
  transition: background-color 0.3s;
  cursor: pointer;
}

.sign-out-button {
  background-color: darkblue;
  color: #f7c639;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-family: Nunito Sans,sans-serif;
  transition: background-color 0.3s;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #f7c639;
  color: darkblue;
}

.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 4px solid #ccc;
  border-style: groove;
  padding: 20px;
  background-color: white;
  width: 100%;  
  padding: 0px;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-content {
  display:flex;
  align-items: center;
  justify-self:end;
}
*, ::after, ::before {
    box-sizing: border-box;
    border-width: 4px;
   /*border-style: solid;*/
    border-color: currentColor;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.header p {
  font-size: 20px;
  font-weight: bold;
}

.header nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

.header nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s;
}

.header nav ul li a:hover {
  color: darkblue;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: white;
  font-size: 14px;
  color: gray;
}

.repository {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
 .title {
  color:#003058;
  font-family: Nunito Sans, sans-serif;
  font-weight:700;
  font-size: x-large;
  margin:20px;
 }
.auth-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 100vh;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.user-actions {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .header p {
    font-size: 18px;
  }

  .header nav ul li a {
    font-size: 14px;
  }

  .footer {
    font-size: 12px;
  }
}

